import React from 'react'
import { Link } from 'gatsby'
import { AttachMoney, CalendarToday, Bookmark } from '@material-ui/icons'

import SlideOverlay from '../SlideOverlay'

import { Wrapper, SlideLink, CourseBody, SubscribeBtn, ReadBtn } from './styles'

const CourseWrapper = props => {
  return (
    <Wrapper>
      <CourseBody>
        <Link to={props.link}>{props.name}</Link>
        <ul>
          <li>
            <CalendarToday />
            <span>
              <b>{props.graduation === 'pos-graduacao ead' || props.graduation === 'pos-graduacao presencial' ? 'Duração mínima' : 'Duração'}: {props.duration} </b>
            </span>
          </li>
          <li>
            <AttachMoney />
            <span>
              Mensalidades a partir de:<b> R$ {props.price}</b> *
            </span>
          </li>
          <li>
            <Bookmark />
            <span>
              <b>Mensalidades:</b> {props.parcel}
            </span>
          </li>
          <span style={{ fontSize: 13 }}>* desconto para pagamento até o vencimento</span>
        </ul>
        <div>
          <SubscribeBtn
            href={props.subscribe || 'https://pag.ae/7VUxxu29q/button'}
            target="_blank"
          >
            Matricule-se
          </SubscribeBtn>
          <ReadBtn href={props.link} target="_blank">
            Saiba mais
          </ReadBtn>
        </div>
      </CourseBody>
    </Wrapper>
  )
}

export default CourseWrapper
