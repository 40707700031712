import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const SectionTitle = styled.h2`
  font-size: 2em;
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 2px solid #ef4e45;
  padding: 12px;
`

export const FilterButton = styled(Button)`
  background-color: #ffc609;
  color: #525fff;
  font-weight: bold;
  text-transform: none;
  width: 390px;
`

export const SearchBar = styled.div`
  margin-top: 16px;
  .MuiInputBase-root {
    width: 70%;
    border-radius: 20px;
    outline: none;
  }
  @media (max-width: 400px) {
    display: flex;
    justify-content: center;
    .MuiInputBase-root {
      width: 100%;
    }
  }
  .MuiButtonBase-root {
    background-color: #00ae9c; 
    color: #fff;
    outline: none;
    border: none;
    padding: 12px 15px;
    margin-left: 5px;
  }
`
