import React from 'react'

import { Overlay } from './styles'

const SlideOverlay = ({ image, alt, children }) => (
  <Overlay style={{ marginTop: 30 }} disableGutters={true}>
    <img src={image} alt={alt} />
    <div className="overlay">{children}</div>
  </Overlay>
)

export default SlideOverlay
