import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import { Container, Input } from '@material-ui/core'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CourseWrapper from '../../components/CourseWrapper'

import { PageTitle } from '../../components/PageTitle'
import { SectionTitle, SearchBar } from '../../styles/pages/presencial'

export default function PosGraduacaoPresencialPage(props) {
  const { data } = props
  const { edges } = data.allMarkdownRemark

  const [filteredCourses, setFilteredCourses] = useState(edges)
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    const posts = filteredCourses.filter(({ node }) =>
      node.frontmatter.name.toLowerCase().includes(query.toLowerCase())
    )
    setSearchResults(posts)
  }, [query.toLowerCase()])

  return (
    <Layout>
      <SEO title="Graduação SemiPresencial" />

      <Container style={{ marginTop: 70 }}>
        <PageTitle>Graduação SemiPresencial</PageTitle>

        <p
          style={{
            marginTop: 50,
            fontSize: 18,
            color: '#333',
            textAlign: 'justify',
            lineHeight: '1.8em',
            wordSpacing: 8,
          }}
        >
          A graduação EaD - {' '}
          <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
            Semipresencial
          </span>{' '}
          ocorre na Sede ou nos Polos da Faculdade Jardins (lista de Polos disponíveis
          no site), onde as atividades presenciais, tais como tutorias, monitorias,
          aulas e avaliações presenciais ocorrem em dias previamente definidos,
          agregados as atividades virtuais que ficam a todo tempo disponíveis aos
          alunos mediante acesso ao AVA* - Ambiente Virtual de Aprendizagem da Faculdade Jardins.
          <br /> <br />
          <span style={{ fontSize: 15, color: '#555' }}>
            *AVA – Ambiente Virtual de Aprendizagem da Faculdade Jardins é uma
            Plataforma de Aprendizagem on-line completa para otimizar a formação
            de nossos alunos, dispondo, inclusive, de vídeos aula, e-books,
            biblioteca virtual, periódicos virtuais, materiais de aula dos
            docentes e tutores, slides, artigos, textos, hipertextos, links,
            chats, fóruns, exercícios, avaliações, etc..
          </span>
        </p>
      </Container>
      <Container disableGutters={true} style={{ marginTop: 60, maxWidth: 1724 }}>
        <SectionTitle>Cursos</SectionTitle>

        <SearchBar>
          <Input
            placeholder="Pesquisar curso"
            value={query}
            onChange={e => setQuery(e.target.value)}
            style={{ padding: 6, borderRadius: 5, border: '2px solid' }}
          />
        </SearchBar>

        <div>
          {searchResults.map(({ node }) => (
            <CourseWrapper
              name={node.frontmatter.name}
              duration={node.frontmatter.duration}
              price={node.frontmatter.price}
              parcel={node.frontmatter.parcelas}
              subscribe={node.frontmatter.subscribe}
              link={node.fields.slug}
              key={node.frontmatter.price}
            />
          ))}
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___position], order: ASC }
      filter: { frontmatter: { category: { eq: "graduacao semipresencial" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            price
            duration
            subscribe
          }
        }
      }
    }
  }
`
