import styled from 'styled-components'
import { Container } from '@material-ui/core'

export const Overlay = styled(Container)`
  position: relative;
  margin-top: 30px;

  &:hover .overlay {
    height: 100%;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 360px;
    height: auto;
    border-radius: 3px;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #008cba;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.8s ease;
    opacity: 0.8;

    a {
      font-size: 20px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }
`
